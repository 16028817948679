import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  contact: ['/','/','/','/'],
  desc: '',
  name: '',
  prof: '',
  title: '',
  link: '',
  edit: 'editCV',
  soc: ['/','/','/','/', '/'],
  VSF: null,
  Ref: null,
  CV: null,
  img: null
}

export const cvSlise = createSlice({
  name: 'cv',
  initialState,
  reducers: {
    setContact: (state, action) => {
      state.contact = action.payload
    },
    setImg: (state, action) => {
      state.img = action.payload
    },
    setRef: (state, action) => {
      state.Ref = action.payload
    },
    setCV: (state, action) => {
      state.CV = action.payload
    },
    setVSF: (state, action) => {
      state.VSF = action.payload
    },
    setDesc: (state, action) => { 
      state.desc = action.payload
    },
    setLink: (state, action) => {
      state.link = action.payload
    },
    setName: (state, action) => {
      state.name = action.payload
      state.title = action.payload
    },
    setProf: (state, action) => {
      state.prof = action.payload
    },
    setSoc: (state, action) => {
      state.soc = action.payload
    }
  }
})

export const {setContact} = cvSlise.actions
export const {setDesc} = cvSlise.actions
export const {setName} = cvSlise.actions
export const {setProf} = cvSlise.actions
export const {setTitle} = cvSlise.actions
export const {setSoc} = cvSlise.actions
export const {setLink} = cvSlise.actions
export const {setImg} = cvSlise.actions
export const {setCV} = cvSlise.actions
export const {setVSF} = cvSlise.actions
export const {setRef} = cvSlise.actions
export default cvSlise.reducer