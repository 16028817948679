import { useEffect } from 'react'
import phone from '../../../image/Vector (2).svg'
import mail from '../../../image/Vector (3).svg'
import web from '../../../image/Vector (4).svg'

function ContactsContent({state}) {

    useEffect(()=>{
      if (state === '') return
      if (state.contact[0] === '/') {
        document.getElementById('oneCon').style.display = 'none'
      }
      if (state.contact[1] === '/') {
        document.getElementById('twoCon').style.display = 'none'
      }
      if (state.contact[2] === '/') {
        document.getElementById('freCon').style.display = 'none'
      }
      if (state.contact[3] === '/') {
        document.getElementById('forCon').style.display = 'none'
      }
    },[])


  return (
    <div className="contact-links">
    <a id='oneCon' href={`tel:${state.contact[0]}`} className="contact-link">
      <div className="contact-link-left">
        <img src={phone} alt="" className="contact-link-left-img" />
      </div>
      <div className="contact-link-right">
        <div className="contact-link-right-title">Mobile phone</div>
        <div className="contact-link-right-desc">{state.contact[0]}</div>
      </div>
    </a>
    <a id='twoCon' href={`tel:${state.contact[1]}`} className="contact-link">
      <div className="contact-link-left">
        <img src={phone} alt="" className="contact-link-left-img" />
      </div>
      <div className="contact-link-right">
        <div className="contact-link-right-title">Alternative phone</div>
        <div className="contact-link-right-desc">{state.contact[1]}</div>
      </div>
    </a>
    <a id='freCon' href={`mailto:${state.contact[2]}`} className="contact-link">
      <div className="contact-link-left">
        <img src={mail} alt="" className="contact-link-left-img" />
      </div>
      <div className="contact-link-right">
        <div className="contact-link-right-title">Email</div>
        <div className="contact-link-right-desc">{state.contact[2]}</div>
      </div>
    </a>
    <a id='forCon' href={state.contact[3]} className="contact-link">
      <div className="contact-link-left">
        <img src={web} alt="" className="contact-link-left-img" />
      </div>
      <div className="contact-link-right">
        <div className="contact-link-right-title">Website</div>
        <div className="contact-link-right-desc">{state.contact[3]}</div>
      </div>
    </a>
  </div>
  );
}

export default ContactsContent;