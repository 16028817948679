import Header from "../../components/header/header";
import LeftPanel from "../../components/leftPanel/leftPanel";
import Main from "../../components/main/main";
import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue,} from "firebase/database";
import PopUp from "../../components/UI/popUp/popUp";
import phone from '../../image/Vector (15).svg'
import mail from '../../image/Vector (16).svg'
import tg from '../../image/Vector (9).svg'
import viber from '../../image/Subtract (4).svg'
import wat from '../../image/Vector (19).svg'

function Home() {

  const [state, setState] = useState(true)

  useEffect(()=>{
    getState()
  },[])

  const getState = () => {
    const db = getDatabase();
    const starCountRef = ref(db, 'site/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val()
      setState(data)
    })
  }
  return (
    <div className="home">
      {state? 
      <div className="home-inner">
        <PopUp/>
        <LeftPanel/>
        <Header/>
        <Main/>
      </div>
      :
      <div className="home-error">
        <div className="div">
          <div className="home-error-title">Website on maintenance. You can still reach me by phones or an email.</div>
            <div className="conteiner">
            <div className="main-contact-inner">
              <div className="main-contact-info">
                <div className="main-contact-info-item main-contact-info-item-mail">
                  <div className="main-contact-info-item-phone">
                    <img src={mail} alt="" />
                    <a href='mailto:as@7ya.ee' className="main-contact-info-item-phone-title">as@7ya.ee</a>
                  </div>
                </div>
                <div className="main-contact-info-item">
                  <div className="main-contact-info-item-phone">
                    <img className='main-contact-info-item-link-img' src={phone} alt="" />
                    <a href='tel:+372 58 330637' className="main-contact-info-item-phone-title">+372 58 330637</a>
                  </div>
                  <div className="main-contact-info-item-links">
                    <a target="_blank" rel="noreferrer" className='main-contact-info-item-link' href='https://wa.me/+37258330637'>
                      <img className='main-contact-info-item-link-img' src={wat} alt="" />
                    </a>
                    <a target="_blank" rel="noreferrer" className='main-contact-info-item-link' href='https://t.me/+37258330637'>
                      <img className='main-contact-info-item-link-img' src={tg} alt="" />
                    </a>
                    <a target="_blank" rel="noreferrer" className='main-contact-info-item-link' href="viber://chat?number=%2B37258330637">
                      <img className='main-contact-info-item-link-img' src={viber} alt="" />
                    </a>
                  </div>
                </div>
                <div className="main-contact-info-item">
                  <div className="main-contact-info-item-phone">
                    <img className='main-contact-info-item-link-img' src={phone} alt="" />
                    <a href='tel:+47 94 1 49 007' className="main-contact-info-item-phone-title">+47 94 1 49 007</a>
                  </div>
                  <div className="main-contact-info-item-links">
                    <a target="_blank" rel="noreferrer" className='main-contact-info-item-link' href="https://wa.me/4794149007">
                      <img className='main-contact-info-item-link-img' src={wat} alt="" />
                    </a>
                    <a target="_blank" rel="noreferrer" className='main-contact-info-item-link' href="https://t.me/AnatolySuglobov">
                      <img className='main-contact-info-item-link-img' src={tg} alt="" />
                    </a>
                    <a target="_blank" rel="noreferrer" className='main-contact-info-item-link' href="viber://chat?number=%2B4794149007">
                      <img className='main-contact-info-item-link-img' src={viber} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      }
    </div>
  );
}

export default Home;